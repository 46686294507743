.text-cursive{
  font-family: "Damion", cursive;
  font-weight: 400;
  font-style: normal;
}
.App {
  text-align: center;
}

.App-logo {
  padding-top: 20px;
  text-align: center;
  width: 150px;
  
}

.logo-text{
  font-size: 40px;
  font-weight: bold;
  color: #61dafb;
  padding: 0;
  margin: 0;
  font-family: "Damion", cursive;
  font-weight: 400;
  font-style: normal;
}
.drag-over {
  border: 5px dashed #61dafb!important;
}
.drop-area{
  margin-top: 5px;
  border: 5px solid rgba(0, 0, 0, 0);
}
.file-row{
  padding: 10px;
}
.App-header {
}

.App-link {
  color: #61dafb;
}
.btn{
  border: none;
  font-weight: 700;
  border-radius: 25px;
  font-size: 22px;
  padding-bottom: 7px;

}
.btn svg{
  padding-bottom: 5px;
  transform: translateY(-4px);
}
.btn-primary{
  background-color: #61dafb;
  color: #393939;


}
label{
  margin-left: 25px;
  display: inline-block;
    font-weight: 700;
    color: #555;
    font-family: "Damion", cursive;
    font-weight: 400;
    font-style: normal;
}
.code-text-area{
  font-family: "Space Mono", monospace;
  font-weight: 400;
  font-style: normal;
}
.my-text-area{
  background-color: #61dafb;
  border-radius: 25px;

}
.cursive-text-area{
  font-family: "Damion", cursive;
  font-weight: 400;
  font-style: normal;
}
.btn{
  font-family: "Damion", cursive;
  font-weight: 400;
  font-style: normal;
}
.remove-btn{
  margin-top: 70px;
    width: 50px;
    height: 50px;
    padding-top: 10px;
}
@media only screen and (max-width: 990px) {
  .remove-btn{
    margin-top: 10px;
  }
}
.add-file{
  border: 5px solid rgba(0, 0, 0, 0);
}
.add-file-drag-over .add-file{
  border: 5px dashed #FFF!important;
}